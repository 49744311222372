<template>
  <el-dialog
    :title="type === 1 ? '新增产品信息' : '修改产品信息'"
    :visible.sync="visible"
    width="20%"
    @close="close"
  >
    <el-form :model="userForm" ref="userForm" :rules="rules">
      <el-form-item prop="name" label="产品名称" label-width="80px">
        <el-input v-model="userForm.name"></el-input>
      </el-form-item>
      <el-form-item prop="investWay" label="产品类型" label-width="80px">
        <el-select
          v-model="userForm.investWay"
          ref="produceRef"
          clearable
          filterable
          placeholder="请选择产品"
          @blur="productSelect"
          allow-create
        >
          <el-option
            v-for="item in istallWayOpt"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="batteryType" label="电池类型" label-width="80px">
        <el-select v-model="userForm.batteryType" placeholder="请选择">
          <el-option
            v-for="item in batteryTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="power" label="产品功率" label-width="80px">
        <el-input v-model="userForm.power"></el-input>
      </el-form-item>
      <el-form-item prop="capacity" label="产品容量" label-width="80px">
        <el-input v-model="userForm.capacity"></el-input>
      </el-form-item>
      <el-form-item prop="prices" label="价格(w/h)" label-width="80px">
        <el-input v-model="userForm.prices"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="type != 3">
      <el-button
        class="btn-blue"
        type="primary"
        style="color: #000"
        @click="onSubmit('userForm')"
        >{{
          type === 1 ? $t("addDevice.add") : $t("addDevice.edit")
        }}</el-button
      >
      <!-- <el-button type="primary" size="mini" v-else>修改</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
import { deviceModelAdd, deviceModelEdit } from "@/api/siteList.js";
import { deepClone } from "@/utils/deepClone";
import i18n from "@/lang";
export default {
  data() {
    return {
      visible: false,
      type: 1, //1新增 2编辑
      userForm: {},
      batteryTypeOptions: [
        {
          label: "梯次电池",
          value: 1,
        },
        {
          label: "新电池",
          value: 2,
        },
      ],
      istallWayOpt: [
        {
          label: "户外柜",
          value: "户外柜",
        },
        {
          label: "集装箱",
          value: "集装箱",
        },
      ],
      customerOptions: [],
      roleOptions: [],
    };
  },
  mounted() {},
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.userForm);
          let obj = JSON.parse(JSON.stringify(this.userForm));
          console.log(obj);
          if (this.type === 1) {
            this.addInfo(obj);
          } else if (this.type === 2) {
            this.editInfo(obj);
          }
        }
      });
    },
    addInfo(obj) {
      console.log("add", obj);
      deviceModelAdd(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    editInfo(obj) {
      console.log("edit", obj);
      deviceModelEdit(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    open(type, row) {
      console.log(typeof type, type);
      this.visible = true;
      this.type = type;
      if (type === 1) {
        this.viewDisable = false;
        this.userForm = {};
      } else if (this.type === 2) {
        this.userForm = deepClone(row);
      } else if (type === 3) {
        this.userForm = deepClone(row);
      }
    },
    close() {
      this.visible = false;
    },
    productSelect() {
      this.userForm.produce = this.$refs.produceRef.selectedLabel;
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-input{
  width 90% !important
}
/deep/ .el-select{
  width 100% !important
}
/deep/ .el-select .el-input{
  width 90% !important
}
</style>
